import React, { forwardRef, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import HidePasswordIcon from '../../svg/eye-hidden.svg';
import ShowPasswordIcon from '../../svg/eye.svg';

export interface ITextInput extends React.ComponentPropsWithoutRef<'input'> {
    className?: string;
    hasError?: boolean;
    label?: string;
    group?: 'top' | 'bottom';
    mousetrap?: boolean;
    icon?: React.ReactNode;
    inputSize?: 'sm' | 'md' | 'lg';
    onTogglePasswordVisibility?: (visible: boolean) => void;
}

const TextInput: React.ForwardRefRenderFunction<HTMLInputElement, ITextInput> = (props, ref) => {
    const {
        className,
        hasError = false,
        label,
        placeholder = '',
        id,
        group,
        mousetrap = true,
        icon,
        disabled,
        inputSize = 'md',
        type,
        onTogglePasswordVisibility,
        ...rest
    } = props;

    const container = useRef<HTMLDivElement>(null);

    const [focused, setFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    return (
        <ContainerStyled className={className} hasError={hasError} focused={focused}>
            {!group && label && (
                <LabelStyled inputSize={inputSize} hasError={hasError} htmlFor={id}>
                    {hasError ? `${label.trim()} (required)` : label}
                </LabelStyled>
            )}
            <TextInputContainerStyled ref={container}>
                <TextInputStyled
                    ref={ref}
                    className={mousetrap ? 'mousetrap' : undefined}
                    id={id}
                    hasError={hasError}
                    placeholder={placeholder}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    group={group}
                    icon={!!icon}
                    disabled={disabled}
                    onFocus={() => {
                        setFocused(true);
                    }}
                    onBlur={() => {
                        setFocused(false);
                    }}
                    inputSize={inputSize}
                    type={showPassword ? 'text' : type}
                    showPasswordToggle={type === 'password'}
                    {...rest}
                />
                {icon && <IconContainerStyled $disabled={disabled}>{icon}</IconContainerStyled>}
                {type === 'password' && (
                    <ToggleStyled
                        type="button"
                        disabled={disabled}
                        onClick={() => {
                            if (onTogglePasswordVisibility) {
                                onTogglePasswordVisibility(!showPassword);
                            }

                            setShowPassword(!showPassword);
                            container.current.querySelector('input')?.focus();
                        }}
                    >
                        {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                    </ToggleStyled>
                )}
            </TextInputContainerStyled>
        </ContainerStyled>
    );
};

const ToggleStyled = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: ${rem(40)};
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
        pointer-events: none;
        opacity: 0.7;
    }

    svg {
        height: ${rem(20)};
        width: ${rem(20)};
        color: ${({ theme }) => theme.palette.LightGrey1};
        transition: color 50ms ease;
    }

    @media (hover: hover) {
        &:hover {
            svg {
                color: ${({ theme }) => theme.palette.White};
            }
        }
    }

    @media (hover: none) {
        svg {
            color: ${({ theme }) => theme.palette.White};
        }
    }
`;

const IconContainerStyled = styled.div<{ $disabled: boolean }>`
    height: ${rem(16)};
    width: ${rem(16)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: ${rem(10)};
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    svg {
        height: 100%;
        width: 100%;
        color: ${({ $disabled, theme }) =>
            $disabled ? theme.palette.LightGrey1 : theme.palette.White};
    }
`;

const LabelStyled = styled.label<{ hasError: boolean; inputSize: ITextInput['inputSize'] }>`
    display: block;
    font-size: ${({ inputSize: size }) => rem(size === 'lg' ? 16 : 13)};
    ${({ theme, inputSize: size }) =>
        size === 'sm' ? theme.typography.FontNormal : theme.typography.FontMedium}
    color: ${({ theme, hasError }) => (hasError ? theme.palette.Red : theme.palette.LightGrey1)};
    margin-bottom: ${rem(8)};
    transition: background-color 100ms ease;
`;

const TextInputStyled = styled.input<{
    hasError: boolean;
    group: ITextInput['group'];
    icon: boolean;
    inputSize: ITextInput['inputSize'];
    showPasswordToggle: boolean;
}>`
    background-color: ${({ theme }) => theme.palette.DarkGrey3};
    border-radius: ${rem(8)};
    line-height: ${rem(32)};
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${({ theme }) => theme.palette.White};
    font-size: ${rem(16)};
    transition: box-shadow 100ms ease, color 100ms ease, opacity 100ms ease,
        background-color 100ms ease;
    width: 100%;
    -webkit-appearance: none;

    ${({ inputSize: size }) => {
        switch (size) {
            case 'sm':
                return css`
                    height: ${rem(32)};
                    padding: 0 ${rem(12)};
                `;
            case 'lg':
                return css`
                    height: ${rem(64)};
                    padding: 0 ${rem(20)};
                `;
            default:
                return css`
                    height: ${rem(40)};
                    padding: 0 ${rem(12)};
                `;
        }
    }}

    ${({ showPasswordToggle }) =>
        showPasswordToggle &&
        css`
            padding-right: ${rem(40)};
        `}

    ${({ icon }) =>
        icon &&
        css`
            padding-right: ${rem(40)};
        `}

    ${({ hasError, theme }) =>
        hasError &&
        css`
            box-shadow: inset 0 0 0 ${rem(1)} ${theme.palette.Red};
        `}

    &::placeholder {
        color: ${({ theme, hasError }) =>
            hasError ? theme.palette.Red : theme.palette.LightGrey1};
        font-size: ${rem(16)};
    }

    &:focus,
    &.focus-visible {
        outline: none;
        background-color: ${({ theme }) => theme.palette.Black};
        box-shadow: inset 0 0 0 ${rem(1)}
            ${({ theme, hasError }) => (hasError ? theme.palette.Red : theme.palette.White)};
    }

    &:disabled {
        opacity: 0.7;
    }

    ${({ group }) =>
        group === 'top' &&
        css`
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        `}

    ${({ group }) =>
        group === 'bottom' &&
        css`
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        `}

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${({ inputSize: size }) => {
            switch (size) {
                case 'sm':
                    return css`
                        font-size: ${rem(13)};

                        &::placeholder {
                            font-size: ${rem(13)};
                        }
                    `;
                case 'lg':
                    return css`
                        font-size: ${rem(16)};

                        &::placeholder {
                            font-size: ${rem(16)};
                        }
                    `;
                default:
                    return css`
                        font-size: ${rem(14)};

                        &::placeholder {
                            font-size: ${rem(14)};
                        }
                    `;
            }
        }}
    }
`;

const TextInputContainerStyled = styled.div`
    position: relative;
    width: 100%;
`;

const ContainerStyled = styled.div<{
    hasError: boolean;
    focused: boolean;
}>`
    z-index: ${({ focused, hasError }) => (focused || hasError ? 2 : 1)};
`;

export default forwardRef(TextInput);
